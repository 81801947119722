<template>
    <div>
      <div class="flex md12">
        <va-card :title="'Multiple Users - Procedure List'">
          <div class="row align--center">
            <div class="flex xs12 md3">
              <va-input
                :value="term"
                :placeholder="'Search procedures'"
                @input="search"
                removable
              >
                <va-icon name="fa fa-search" slot="prepend" />
              </va-input>
            </div>
            <div class="flex xs12 md3 mb-3">
              <!-- create button and allign right -->
            </div>
            <div class=" flex xs12 md3 offset--md3">
              <va-select
                v-model="perPage"
                :label="$t('tables.perPage')"
                :options="perPageOptions"
                no-clear
              />
            </div>
          </div>
  
          <va-data-table
            :fields="fields"
            :data="filteredData"
            :per-page="parseInt(perPage)"
            :loading="loading"
            clickable
            hoverable
            class="overflow-hidden"
          >
            <template slot="uid" slot-scope="props">
              <span
                @click="showField('Procedure Uid', props.rowData.id)"
                :title="props.rowData.id"
                >{{ props.rowData.id }}</span
              >
            </template>
            <template slot="accounts_count" slot-scope="props">
              <span
                @click="
                  viewDetails(props.rowData)
                "
                :title=" props.rowData.users.accounts.length + ' accounts'"
                >{{props.rowData.users.accounts.length}} Account(s)</span
              >
            </template>
            <template slot="defaultPassword" slot-scope="props">
              <span
                @click="showField('User Default Password', props.rowData.users.defaultPassword)"
                :title="props.rowData.users.defaultPassword"
                >{{ props.rowData.users.defaultPassword }}</span
              >
            </template>
            <template slot="createdAt" slot-scope="props">
              <span
                @click="showField('Creation Date', formatDate(props.rowData.users.createdAt._seconds))"
                :title="formatDate(props.rowData.users.createdAt._seconds)"
                >{{ formatDate(props.rowData.users.createdAt._seconds) }}</span
              >
            </template>
  
            <template slot="actions" slot-scope="props">
              <div class="flex row">
                <va-button
                  @click="viewDetails(props.rowData)"
                  title="View Details"
                  color="info"
                  icon="fa fa-search"
                />
              </div>
            </template>
          </va-data-table>
          <va-button @click="loadData()" color="primary">
            <i
              class="va-icon iconicstroke iconicstroke-bolt"
              style="margin-right: 1em;"
            />
            Refresh
          </va-button>
        </va-card>
      </div>
    </div>
  </template>
  
  <script>
  import { debounce } from "lodash";
  import moment from "moment";
  import axios from "@/scripts/interceptor.js";
  import firebase from "firebase";
  export default {
    data() {
      return {
        email: "",
        loading: true,
        term: null,
        procedures: [],
        perPage: "10",
        perPageOptions: ["10", "50", "100", "250"],
        procedure: {}
      };
    },
    async created() {
      await this.loadData();
    },
    computed: {
      fields() {
        return [
          {
            name: "__slot:uid",
            title: "Procedure Uid",
            sortField: "id",
            width: "20%"
          },
          {
            name: "__slot:accounts_count",
            title: "Accounts Linked",
            width: "20%"
          },
          {
            name: "__slot:defaultPassword",
            title: "Default Password",
            width: "20%"
          },
          {
            name: "__slot:createdAt",
            title: "Creation Date",
            width: "20%"
          },
          {
            name: "__slot:actions",
            title: "Actions",
            width: "20%"
          }
        ];
      },
      filteredData() {
        //ORDER BY CREATED DATE
        return this.procedures.sort((a, b) => {
          return b.users.createdAt._seconds - a.users.createdAt._seconds;
        });
      }
    },
    methods: {
      formatDate(date) {
        return moment.unix(date).format("MM/DD/YYYY HH:mm:ss");
      },
      async loadData() {
        this.loading = true;
        this.procedures = [];
        const uid = this.$route.params.uid;
        try {
          const procedureDetails = await axios.get(`/api/users/procedure/list` );
          if (procedureDetails.data.data) {
            this.procedures = procedureDetails?.data?.data;
          }
          console.log(this.procedures)
          this.loading = false;
        } catch (error) {
          this.loading = false;
          console.log(error);
        }
       

      },
      viewDetails(procedure) {
        let uid = procedure.id;
        this.$router.push(`/users/procedure/details/${uid}`);
      },
      showField(title, text) {
        //show swal with the text
        this.$swal({
          title: title,
          icon: "info",
          html: text,
          confirmButtonText: "Close"
        });
      },
      search: debounce(function(term) {
        /* eslint-disable */
        this.procedures = term;
      }, 100)
    }
  };
  </script>
  <style>
  .overflow-hidden {
    overflow: hidden !important;
  }
  </style>
  